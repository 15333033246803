import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Header() {
    const [open, setOpen] = useState(false);
    return (
        <header>
            <nav className="navbar navbar-expand-lg bg-white">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="header-logo" src="./assets/ParkGO 1.png" alt="Logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        aria-expanded={open ? "true" : "false"}
                        aria-label="Toggle navigation"
                        onClick={() => setOpen(prev => !prev)}
                    >
                        <img className="navbar-toggler-icon toggle-btn" src="./assets/menu-svgrepo-com.svg" alt="Toggle navigation" />
                    </button>
                    <div className={`collapse navbar-collapse ${open ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#service">Products</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#faq">FAQ</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link contact-us-text cont-btn button-4" to="/contact">Contact Us</Link>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link contact-us-text signin cont-btn button-4"
                                    href="https://stek.parkgo.justapay.in/login-user/"
                                    rel="noopener noreferrer"
                                >
                                    Sign In
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
