import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Testimonials() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // Optional: slides to move at once
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="testimonial">
      <div className="testimonial-wrapper px-5 container bg-white">
        <div className="client">
          <p className="testimonial-heading">
            What <span>Our Clients Say</span>
          </p>
          <div className="d-flex align-items-center justify-content-center gap-5"></div>
        </div>
        <div className="slider-containe">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // Enable server-side rendering
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000} // Slow down the autoplay speed for better readability
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            renderButtonGroupOutside={true}
            arrows={true} // Optional: Show arrows if needed
          >
            <div className="testimonial-card">
              <div className="d-flex align-items-center testimonial-inner-wrapper ">
                {/* <img src="./assets/client-image.png" /> */}
                <div className="review-box">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div>
                      <p className="client-name">Keshav Goyal
                      </p>
                      <p className="client-designation m-0">
                    Business head
                      </p>
                    </div>
                    <div className="text-end">
                      <span className="m-0">
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                      </span>
                      <p className="m-0 thumb-text">
                        <span>
                          <img src="./assets/thumb-img.png" />
                        </span>
                        <span>Testimonial</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="review-details">
                I have been using this software for over a year now and I love
                it! I can't imagine life without it. It's so easy to use, and
                the customer service is great.
              </p>

              <div className="position-absolute">
                <img src="./assets/Vector.png" />
              </div>
            </div>

            <div className="testimonial-card">
              <div className="d-flex align-items-center testimonial-inner-wrapper ">
                {/* <img src="./assets/client-image.png" /> */}
                <div className="review-box">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div>
                      <p className="client-name">Yash Maheswari</p>
                      <p className="client-designation m-0">
                     CEO
                      </p>
                    </div>
                    <div className="text-end">
                      <span className="m-0">
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                     
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                      </span>
                      <p className="m-0 thumb-text">
                        <span>
                          <img src="./assets/thumb-img.png" />
                        </span>
                        <span>Testimonial</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="review-details">
              I recently started using ParkGo and I'm blown away by how much easier it's made my daily commute. 
              The app is incredibly user-friendly, with a simple and intuitive interface.
              </p>

              <div className="position-absolute">
                <img src="./assets/Vector.png" />
              </div>
            </div>

            <div className="testimonial-card">
              <div className="d-flex align-items-center testimonial-inner-wrapper ">
                {/* <img src="./assets/client-image.png" /> */}
                <div className="review-box">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div>
                      <p className="client-name">Abhinav
                      </p>
                      <p className="client-designation m-0">
                        Marketing Coordinator 
                      </p>
                    </div>
                    <div className="text-end">
                      <span className="m-0">
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                        <span>
                          <img src="./assets/Star 2.png" />
                        </span>
                      </span>
                      <p className="m-0 thumb-text">
                        <span>
                          <img src="./assets/thumb-img.png" />
                        </span>
                        <span>Testimonial</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="review-details">
              I have been using this software for over a year now. It's so easy to use, and the customer service is great.
              </p>

              <div className="position-absolute">
                <img src="./assets/Vector.png" />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
