import React, { useEffect } from 'react'

function Scroll() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
       <></>
    )
}

export default Scroll