import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        mobile_number: '',
        city: '',
        state: '',
        quantity: "100-500",
        message: '',

    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    // statedata
    const STATE_DATA = [
        { code: "AN", value: "Andaman and Nicobar Islands" },
        { code: "AP", value: "Andhra Pradesh" },
        { code: "AR", value: "Arunachal Pradesh" },
        { code: "AS", value: "Assam" },
        { code: "BR", value: "Bihar" },
        { code: "CG", value: "Chandigarh" },
        { code: "CH", value: "Chhattisgarh" },
        { code: "DH", value: "Dadra and Nagar Haveli" },
        { code: "DD", value: "Daman and Diu" },
        { code: "DL", value: "Delhi" },
        { code: "GA", value: "Goa" },
        { code: "GJ", value: "Gujarat" },
        { code: "HR", value: "Haryana" },
        { code: "HP", value: "Himachal Pradesh" },
        { code: "JK", value: "Jammu and Kashmir" },
        { code: "JH", value: "Jharkhand" },
        { code: "KA", value: "Karnataka" },
        { code: "KL", value: "Kerala" },
        { code: "LD", value: "Lakshadweep" },
        { code: "MP", value: "Madhya Pradesh" },
        { code: "MH", value: "Maharashtra" },
        { code: "MN", value: "Manipur" },
        { code: "ML", value: "Meghalaya" },
        { code: "MZ", value: "Mizoram" },
        { code: "NL", value: "Nagaland" },
        { code: "OR", value: "Odisha" },
        { code: "PY", value: "Puducherry" },
        { code: "PB", value: "Punjab" },
        { code: "RJ", value: "Rajasthan" },
        { code: "SK", value: "Sikkim" },
        { code: "TN", value: "Tamil Nadu" },
        { code: "TS", value: "Telangana" },
        { code: "TR", value: "Tripura" },
        { code: "UK", value: "Uttarakhand" },
        { code: "UP", value: "Uttar Pradesh" },
        { code: "WB", value: "West Bengal" },
    ];
    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'name':
                if (!value) error = 'Merchant Name is required';
                break;
            case 'email':
                if (!value) error = 'Email is required';
                else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email is invalid';
                break;
            case 'mobile_number':
                if (!value) error = 'Mobile Number is required';
                else if (!/^\d{10}$/.test(value)) error = 'Mobile Number must be 10 digits';
                break;
            case 'city':
                if (!value) error = 'City is required';
                break;
            case 'state':
                if (!value) error = 'State is required';
                break;
            case 'quantity':
                if (!value) error = 'Quantity is required';
                break;
            default:
                break;
        }
        return error;
    };

    const validate = () => {
        const newErrors = {};
        Object.keys(formValues).forEach(key => {
            const error = validateField(key, formValues[key]);
            if (error) newErrors[key] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleBlur = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await fetch('https://parkgo.testinguat.com:5436/api/enquiry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                });


                if (response) {
                    const data = await response.json();
                    setFormValues({
                        name: '',
                        email: '',
                        mobile_number: '',
                        city: '',
                        state: '',
                        quantity: '',
                        message: '',
                    })
                    console.log('Form submitted successfully:', data);
                    toast.success('Form submitted successfully!');
                    if (data.success) {
                        // toast.success('Form submitted successfully!', {

                        // });
                    }
                } else {
                    console.error('Error submitting form:', response.statusText);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };


    return (
        <section className='contact-us'>
            <div className='container'>
                <div className='contact-us-wrapper'>
                    <p className='contact-heading'>
                        Get Started with ParkGo Today
                    </p>
                    <p className='contact-deatils'>
                        Get a Quote Immediately Upon Form Submission
                    </p>
                    <div className='row mt-5'>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 contact-img-wrapper'>
                            <img className='' src="./assets/contact-us.png" />
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-wrapper'>
                                    <div className='flex-width'>
                                        <div class="mb-3">
                                            <input placeholder='Name'
                                                name='name'
                                                onChange={handleChange}
                                                onBlur={handleBlur} type="text" class="form-control contact-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            {errors.name && <p className='text-danger error-text'>{errors.name}</p>}
                                            <input placeholder='Phone Number'
                                                name='mobile_number'
                                                value={formValues.mobile_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur} type='number' class="form-control contact-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            {errors.mobile_number && <p className='text-danger error-text'>{errors.mobile_number}</p>}
                                            <select name='state'
                                                onChange={handleChange}
                                                onBlur={handleBlur} class="form-select contact-input" aria-label="Default select example">
                                                <option value=''>Select state</option>
                                                {STATE_DATA.map((item) => (

                                                    <option value={item.value}>{item.value}</option>
                                                ))}

                                            </select>
                                            {errors.state && <p className='text-danger error-text'>{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className='flex-width'>
                                        <div class="mb-3">
                                            <input type="email" class="form-control contact-input" id="exampleInputEmail1" aria-describedby="emailHelp" name='email'
                                                placeholder='Email'
                                                value={formValues.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.email && <p className='text-danger error-text'>{errors.email}</p>}
                                            <input placeholder='City' class="form-control contact-input" id="exampleInputEmail1" aria-describedby="emailHelp" name='city'
                                                type='text'
                                                value={formValues.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.city && <p className='text-danger error-text'>{errors.city}</p>}
                                            <select name='quantity'
                                                onChange={handleChange}
                                                onBlur={handleBlur} class="form-select contact-input" aria-label="Default select example">
                                                <option value=''>Select Quantity</option>
                                                <option value='100-500'>100-500</option>
                                                <option value='500-1000'>500-1000</option>
                                                <option value='1000+'>1000+</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='message-wrapper'>
                                            <label for="exampleFormControlTextarea1" class="form-label textarea-text">Message</label>
                                            <textarea class="form-control message-input-text" placeholder='Write us message for further inquiries' id="exampleFormControlTextarea1" rows="3" value={formValues.message}
                                                name='message'
                                                onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                    <label class="form-check-label check-label-text" for="exampleCheck1">I agree to receive information, offers and updates from ParkGo*</label>
                                </div>
                                <div className='mt-5 text-center'>
                                    <button className='submit-btn' type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact