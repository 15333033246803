import React from 'react'
import { Link } from 'react-router-dom'

function About() {
    return (
        <div id='home'>
            <section className='hero-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 webview'>
                            <img className='parking-img' src='./assets/parking-concept-illustration 1.png'></img>
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6'>
                            <div className='main-haeding-wrapper'>
                                <h1 className='main-heading'> Innovative Technology for<span>Unmanaged Parking</span> </h1>
                                <p className='para-deatils'>
                                    Transform Your Parking with ParkGo.
                                </p>
                                <Link
                                    to="https://stek.parkgo.justapay.in/login-user/">

                                    <button className='signin-btn'>
                                        Sign in
                                    </button>
                                </Link>
                            </div>

                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 phoneview'>
                            <img className='parking-img' src='./assets/parking-concept-illustration 1.png'></img>
                        </div>
                    </div>
                </div>
            </section>


            <section class="about-us">
                <div class="container">
                    <div class="row align-items-end ">

                        <div class="col-xl-6  col-lg-6 col-md-6 col-sm-12 col-xs-12 about-us-img-wrapper ">
                            <img class="about-us-img" src="./assets/about-us.png" alt="" />
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <p class="about-us-heading text-center">
                                About <span>
                                    Us
                                </span>
                            </p>
                            <div class="aboutus-content-wrapper">
                                <p class="aboutus-title ">
                                    ParkGo
                                </p>
                                <p class="aboutus-details">
                                    At ParkGo, we understand that retailers may find it difficult to maintain parking spaces. We provide modern hardware and software solutions to businesses that are intended to improve customer experiences and optimize operations for the unmanaged parking system. Merchants can effortlessly monitor and manage their parking operations with the help of a robust management dashboard, real-time data analytics, and mobile app connectivity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="parkgo-used">
                <div class="container">
                    <p class="used-heading text-center">
                        Where Can <span>
                            We Used
                        </span> ?
                    </p>
                </div>
                <div class="">
                    <div class="row Used-wrapper justify-content-center gap-5 overflow-hidden m-0">
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                            <img src="./assets/Shopping Malls and Buildings.svg" />
                            </div>
                           
                            <p class="used-card-titile">
                                Shopping Malls and Buildings
                            </p>
                            <p class="used-details-text">
                                Can be used widely in malls and building parking areas.

                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Tourists places.svg" />
                            </div>
                            <p class="used-card-titile">
                                Tourists places
                            </p>
                            <p class="used-details-text">
                                Manages parking for tourists and traveling places
                                .
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Schools and Colleges.svg" />
                            </div>
                            <p class="used-card-titile">
                                Schools and Colleges
                            </p>
                            <p class="used-details-text">
                                Helpful for unmanaged parking in schools and colleges.
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Corporate building,.svg" />
                            </div>
                            <p class="used-card-titile">
                                Corporate building

                            </p>
                            <p class="used-details-text">
                                Streamlines vehicle parking in corporate offices and buildings.

                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Theaters and Events.svg" />
                            </div>
                            <p class="used-card-titile">
                                Theaters and Events
                            </p>
                            <p class="used-details-text">
                                Ease your parking problems in theaters and event programs
                                .
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Parks.svg" />
                            </div>
                            <p class="used-card-titile">
                                Parks

                            </p>
                            <p class="used-details-text">
                                Manage the unmanaged parking in parks and playgrounds.

                            </p>
                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className='icon-wrapper'>
                            <img src="./assets/Market places.svg" />
                            </div>
                            <p class="used-card-titile">
                                Market places
                            </p>
                            <p class="used-details-text">
                                Useful for crowd management in markets
                                .

                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default About