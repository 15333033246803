import React from 'react'
import { Link } from 'react-router-dom'

function FAQ() {
    return (
        <div id="faq">   <section class="contactus" >
            <div class="container">
                <div class="contact-wrapper">
                    <div class="contact-contemt-wrapper">
                        <p class="contact-title">
                            Contact Us
                        </p>
                        <p class="contact-details-text">Have a question or concern about parking on campus? Get Started with ParkGo Today</p>
                        <Link class="reach-out-btn contact-us-text cont-btn button-4"  to="/contact"> Reach Out</Link>
                        
                    </div>
                </div>
            </div>
        </section>
            <section class="faq">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="faq-card shadow">
                                <p class="faq-heading">
                                    Frequently Asked Questions
                                </p>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button " type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                               Do you provide require software and hardware both?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p class="accordion-text mb-0 ">Yes, we provide both</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                              Is it only for merchants? 
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p class="accordion-text mb-0"> Yes </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                               In how much do you deliver your services?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p class="accordion-text mb-0"> We do provide jn minimum time and product according to the dispatch and your requirements.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsefour" aria-expanded="false"
                                                aria-controls="collapsefour">
                                               How time do you take in software solution?
                                            </button>
                                        </h2>
                                        <div id="collapsefour" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p class="accordion-text mb-0">Software will be delivered within 24 hours</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsefive" aria-expanded="false"
                                                aria-controls="collapsefive">
                                                Can we order for multiple products?
                                            </button>
                                        </h2>
                                        <div id="collapsefive" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p class="accordion-text mb-0"> Yes, you can</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 faq-img-wrapper">
                            <img class="faq-img" src="./assets/faq.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FAQ