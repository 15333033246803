import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (

        <footer class="footer-wrapper">

            <div class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-6">
                            <p class="footer-title">
                                Download App
                            </p>
                            <img src="./assets/div.elementor-element.png" />
                            <div className='bar-code-img'>
                                <img src="./assets/barcode.png" />
                            </div>
                            <div class="d-flex align-items-center mt-3 gap-2">
                                <a href="">
                                    <img class="google-play-img" src="./assets/google-play.png" alt="" />
                                </a>
                                <a href="">
                                    <img class="google-play-img" src="./assets/app-store.png" />
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 ">
                            <p class="footer-title">
                                Useful Links
                            </p>
                            <img src="./assets/div.elementor-element.png" />
                            <div className='list-wrapper'>
                                <div class="d-flex align-items-center gap-3 mb-3">
                                    <img src="./assets/Icon.png" />
                                    <a class="footer-tabs" href="#home" >
                                     Home
                                    </a>
                                </div>
                                <div class="d-flex align-items-center gap-3 mb-3">
                                    <img src="./assets/Icon.png" />
                                    <a class="footer-tabs" href="#about" >
                                        About Us
                                    </a>

                                </div>
                              
                                <div class="d-flex align-items-center gap-3 mb-3">
                                    <img src="./assets/Icon.png" />
                                    <a class="footer-tabs" href='#plans'>
                                        Our Plans
                                    </a>
                                </div>
                                <div class="d-flex align-items-center gap-3 mb-3 display-text">
                                    <img src="./assets/Icon.png" />
                                    <Link class="footer-tabs" href='' to="/contact">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-6 ">
                            <p class="footer-title">
                                Location
                            </p>
                            <img src="./assets/div.elementor-element.png" />
                            <div>
                                <div class="d-flex align-items-center gap-3 mb-3">
                                    <img class="location-icon" src="./assets/Vector (1).png" />
                                    <p class="footer-tabs">
                                        Jaipur, Rajasthan, India
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="footer-details-wrapper d-flex align-items-center bg-white gap-5">
                    <Link to={"/"}>
                        <img class="footer-logo" src="./assets/ParkGO 2.png" alt=" footer logo" />
                    </Link>
                    <p class="m-0 brand-deatils">
                        At ParkGo, we empower merchants with cutting-edge hardware and software solutions designed to streamline operations and enhance customer experiences.
                    </p>
                </div>
            </div>
            <div>
                <p class="Copyrighte-text">
                    © Copyrighted & Designed by ParkGo
                </p>
            </div>
        </footer>
    )
}

export default Footer