import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './component/header/Header';
import Footer from './component/footer/Footer';

import Contact from './pages/contact/Contact';
import Home from './component/home/Home';
import Scroll from './common/Scroll';
import ScrollToTop from './common/ScrollToTop';
import { ToastContainer } from 'react-toastify';




function App() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <div className="">

      <BrowserRouter>
      <ScrollToTop/>
      <ToastContainer />
        <Header />
        <Scroll/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
