import React from "react";
import About from "../../pages/about/About";
import Products from "../../pages/product/Products";
import Testimonials from "../../pages/testimonial/Testimonials";
import Plans from "../../pages/plan/Plans";
import FAQ from "../../pages/faq/FAQ";
function Home() {
  return (
    <div>
      <div id="about">
        <About />
      </div>
      <Products />
      <Plans />
      <Testimonials />
      <div id="faq">
        <FAQ />
    </div>
    </div>
  );
}

export default Home;
